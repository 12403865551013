document.addEventListener("DOMContentLoaded", () => {
  const animatedTextSections = [...document.querySelectorAll(".animated-text")];

  animatedTextSections.forEach(section => {
    let paragraphs = [...section.querySelectorAll(".animated-text__description-primary p")];
    let spans = [];

    paragraphs.forEach(paragraph => {
      let htmlString = "";
      let pArray = paragraph.textContent.split("");

      for (let i = 0; i < pArray.length; i++) {
        htmlString += `<span>${pArray[i]}</span>`;
      }

      paragraph.innerHTML = htmlString;
    });

    spans = [...section.querySelectorAll(".animated-text__description-primary span")];

    function getColors() {
      let parentClassList = section.classList;
      if (parentClassList.contains("white")) {
        return {
          startColor: [116, 117, 131], // rgb values for #071C15 (start color)
          endColor: [255, 255, 255],   // rgb values for #ffffff (end color)
        };
      } else if (parentClassList.contains("orange")) {
        return {
          startColor: [116, 117, 131], // rgb values for #071C15 (start color)
          endColor: [247, 69, 20],     // rgb values for #FFA500 (orange)
        };
      } else {
        return {
          startColor: [0, 0, 0],       // rgb values for #071C15 (start color)
          endColor: [0, 0, 0],         // rgb values for #ffffff (end color)
        };
      }
    }

    function revealSpans() {
      const colors = getColors();
      const triggerPoint = section.dataset.triggerPoint ? parseFloat(section.dataset.triggerPoint) : 0.5; // Default to 0.5 if not set

      for (let i = 0; i < spans.length; i++) {
        if (
          spans[i].parentElement.getBoundingClientRect().top <
          window.innerHeight * triggerPoint
        ) {
          let { left, top } = spans[i].getBoundingClientRect();
          top = top - window.innerHeight * triggerPoint;

          let opacityValue = 1 - (top * 0.01 + left * 0.001);
          opacityValue =
            opacityValue < 0.3
              ? 0.3
              : opacityValue > 1
              ? 1
              : opacityValue.toFixed(3);

          spans[i].style.opacity = opacityValue;

          // Convert opacity value (0.3 to 1) to a value for the color interpolation (0 to 1)
          let colorValue = (opacityValue - 0.3) / 0.7;

          // Interpolate between the two colors
          let red = Math.round(
            colors.startColor[0] + (colors.endColor[0] - colors.startColor[0]) * colorValue
          );
          let green = Math.round(
            colors.startColor[1] + (colors.endColor[1] - colors.startColor[1]) * colorValue
          );
          let blue = Math.round(
            colors.startColor[2] + (colors.endColor[2] - colors.startColor[2]) * colorValue
          );

          spans[i].style.color = `rgb(${red}, ${green}, ${blue})`;
        }
      }
    }

    window.addEventListener("scroll", revealSpans);
    revealSpans();
  });
});
