class UploadButton {
  constructor(buttonSelector, inputSelector) {
      this.buttonElement = document.querySelector(buttonSelector);
      this.inputElement = document.querySelector(inputSelector);

      this.init();
  }

  init() {
      if (this.buttonElement && this.inputElement) {
          this.inputElement.addEventListener('focus', () => this.addFocusClass());
          this.inputElement.addEventListener('blur', () => this.removeFocusClass());
      }
  }

  addFocusClass() {
      this.buttonElement.classList.add('focused');
  }

  removeFocusClass() {
      this.buttonElement.classList.remove('focused');
  }
}

const uploadButton = new UploadButton('.btn--upload', '#file');
