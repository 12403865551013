import Swiper from "swiper";
import { Mousewheel, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";

class GallerySlider {
  constructor(el) {
    this.el = el;
    this.swiper = this.el.querySelector(".swiper");

    if (this.swiper) {
      new Swiper(this.swiper, {
        autoHeight: false,
        spaceBetween: 25,
        slidesPerView: 1.4,
        loop: false,
        grabCursor: true,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        direction: "horizontal",
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },

        breakpoints: {
          667: {
            slidesPerView: 2.5,
          },
          768: {
            slidesPerView: 2.4,
          },
          992: {
            slidesPerView: 2.6,
            slidesOffsetAfter: 42,
            slidesOffsetBefore: 42,
          },
          1920: {
            slidesPerView: 2.8,
            slidesOffsetAfter: 60,
            slidesOffsetBefore: 60,
          },
        },
        navigation: {
          prevEl: this.el.querySelector(".swiper-arrows .swiper-arrows__prev"),
          nextEl: this.el.querySelector(".swiper-arrows .swiper-arrows__next"),
        },
        modules: [Navigation, Mousewheel],
      });
    }
  }
}

function initGallerySlider() {
  const sliders = document.querySelectorAll(".js-gallery");
  for (let slider of sliders) {
    new GallerySlider(slider);
  }
}

initGallerySlider();
